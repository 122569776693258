@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  background-color: var(--common-white);
  padding: spacing(1.75, 1.5);

  @include devices(s-up) {
    padding-left: spacing(2);
    padding-right: spacing(2);
  }
}

.expertName {
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  line-height: 1.3;
  color: var(--text-primary);
  display: -webkit-box;
  white-space: nowrap;
  line-clamp: 2;
  box-orient: vertical;
  overflow: hidden;
  margin: 0;

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.avatarWrapper {
  margin: spacing(0, 1.5, 0, 0);

  img {
    height: auto;
    max-width: 80px;
    width: auto;
  }

  .onlineStatus {
    min-width: 15px;
    min-height: 15px;
  }
}

.avatarWrapperCover {
  img {
    width: 42px;
    height: 49px;
    object-fit: cover;
  }
}

.detailsCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: var(--text-primary);
  margin: spacing(0, 1, 0, 0);
}

.detailsLink {
  display: block;
  line-height: 0;
}

.productsList {
  width: initial;

  &.widthLargerThan600 {
    width: 300px;
  }

  flex-direction: column;
}

.icon {
  margin-top: spacing(1);
}

.iconWithRating {
  margin-top: spacing(0.625);
}

.rating {
  display: inline-flex;
  align-items: center;
}

.ratingItems {
  span {
    display: block;
    width: 14px;
    height: 14px;
  }

  svg {
    display: block;
    width: 14px;
    height: 14px;
  }
}

.line {
  height: 1px;
  background-color: var(--contextual-colors-neutral);
  margin: spacing(0, 2);
}

.updateFavouritesIcon {
  display: flex;
  align-items: center;
}

.updateFavouritesAction {
  width: auto;
  height: auto;
  background-color: transparent;
  color: var(--primary-main);
  display: flex;
  align-items: center;
  align-self: center;
  padding: spacing(1, 1.1, 1, 1.6);
  border: 1px solid;
  border-radius: 20px;
  transition: background 0.15s ease;

  span {
    font-size: rem(27);
    line-height: 1rem;
  }

  svg {
    width: 24px;
    height: 20px;
  }

  &:hover {
    border: 1px solid;
  }
}

.metaWrapper {
  display: flex;
  align-items: center;
}

.customPrice {
  display: block;
}

.promoList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.tagsWrapper {
  margin-right: spacing(0.25);
}

.tagClassName {
  height: auto;
  padding: spacing(0.25, 0.5);
  font-weight: var(--font-weight-medium);

  span {
    font-size: rem(8);
  }
}
