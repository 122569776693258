@use "@styles/partials/functions" as *;

.paymentBase {
  justify-content: space-between;
  position: relative;
}

.currentMethodWrapper {
  display: flex;
  border: 1px solid var(--grey-400);
  border-radius: 4px;
  min-height: 50px;
  align-items: center;
  padding: spacing(1.5);
  background-color: var(--common-white);
}

.withEditButton {
  padding-right: spacing(8);
}
