@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.wrapper {
  padding: spacing(2);
  background-color: var(--common-white);
  width: 100%;
  border-radius: 4px;
  display: flex;
  margin-bottom: spacing(2);
}

.avatarWrapper {
  width: 80px;
  height: 93px;
  margin: spacing(0, 1.25, 1.25, 0);
  float: left;

  @include devices(sm-up) {
    margin: spacing(0, 3.75, 1.25, 0);
  }

  @include devices(md-up) {
    float: none;
    width: 160px;
    height: 187px;
  }
}

.avatarSkeleton {
  min-height: 100%;
}

.detailsCol {
  flex: 1;
}
