@use "@styles/partials/functions" as *;

.rowRoot {
  & > * {
    border-bottom: unset;
  }
}

.rowCell {
  padding: spacing(1, 0);
}

.rowButtonCell {
  cursor: pointer;
  text-align: center;
  font-size: 0;
  padding: 0;
}

.row {
  font-size: rem(12);
  display: flex;
  align-items: top;
  justify-content: space-between;
}

.collapseRow {
  & > td {
    border: none;
  }
}

.collapseCell {
  padding: spacing(0, 2, 0, 10);
}

.line {
  border-bottom: 1px solid var(--contextual-colors-neutral);
  margin-bottom: spacing(1.5);
}

.expertPhoto {
  border-radius: 4px;
  max-width: 34px;
}

.mainTitle {
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  line-height: 1.86;
  display: flex;
  align-items: center;
  gap: spacing(1);
}

.title {
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
}

.subTitle {
  font-size: rem(12);
  font-weight: var(--font-weight-medium);
  color: var(--contextual-colors-text-body);
}

.bodyText {
  border-bottom: none;
}

.priceIncoming {
  background-color: var(--contextual-colors-bg-primary-accent);
  color: var(--primary-dark);
  border-radius: 4px;
  padding: spacing(0.25);
}

.envelopeIcon {
  width: 18px;
  height: 18px;
}
