@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.contentWrapper {
  background-color: var(--grey--a200);
  border-radius: 0px 20px 20px 20px;
  padding: spacing(1.5);
  position: relative;

  @include devices(sm-up) {
    padding: spacing(2);
  }
}

.content {
  font-weight: var(--font-weight-medium);
  line-height: 22px;
  white-space: pre-line;

  @include devices(sm-up) {
    font-size: rem(16);
    line-height: 24px;
  }
}

.attachments {
  margin-top: 0;
}
