.buttonBase {
  justify-content: left !important; // inject order bug https://adviqo.atlassian.net/browse/MISSION-3281?focusedCommentId=201950
  font-weight: var(--font-weight-medium);
  border-width: 1px;

  &:hover {
    color: var(--text-primary);
    border-color: var(--contextual-colors-brand-primary-hover);
  }
}

.buttonBaseActive {
  background-color: var(--action-selected);
  color: var(--primary-main);
  border-color: var(--primary-main);

  &:hover,
  &:focus,
  &:active {
    color: var(--text-primary);
    border-color: var(--primary-main);
  }
}