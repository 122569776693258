@use "@styles/partials/breakpoints" as *;

$APP_BAR_HEIGHT: 64px;

.headerRootExtended {
  width: 100%;
  background-color: var(--common-white);
  z-index: 101;
  top: 43px;
  height: 36px;
  border-bottom: none;
  position: fixed;

  @include devices(md-up) {
    top: $APP_BAR_HEIGHT;

    &:before {
      content: "";
      width: 100%;
      height: 40px;
      position: fixed;
      top: $APP_BAR_HEIGHT;
      left: 0;
      background-color: white;
    }
  }
}

.tabsClassName {
  min-height: 0;
}
