@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  background-color: rgba(var(--primary-main), 0.1);
  border-radius: 4px;
  padding: spacing(2, 1.5);

  @include devices(sm-up) {
    padding: spacing(4, 1.5);
  }
}

.spinnerWrapper {
  margin-bottom: spacing(2);
  text-align: center;
}

.spinner {
  display: inline-block;
}

.loadingMessage {
  font-size: rem(16);
  font-weight: var(--font-weight-medium);
  text-align: center;
}
