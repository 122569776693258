@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.tabClassName {
  text-transform: none;
  min-width: unset;
  min-height: unset;
  font-size: rem(14);
  line-height: 1.325;

  @include devices(md-up) {
    font-size: rem(16);

    &:first-child {
      margin-left: spacing(7.5);
    }
  }
}
