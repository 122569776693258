@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.paperBody {
  padding: spacing(0, 1.5);

  @include devices(md-up) {
    padding: 0;
  }
}

.calendarWrapper {
  display: flex;
  align-items: center;
  padding: spacing(1.75, 0, 1.5);
  border-bottom: solid 1px var(--grey-300);

  @include devices(md-up) {
    padding: spacing(2, 0, 2.375, 7.5);
    border-bottom: none;
  }
}

.monthsSelect {
  margin-left: spacing(1);
  font-size: rem(14);
  font-weight: bold;

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.errorTitle {
  font-weight: var(--font-weight-medium);
  font-size: rem(19);
  line-height: 25px;
}

.errorText {
  font-weight: var(--font-weight-medium);
  font-size: rem(16);
  line-height: 21px;
}

.errorIcon {
  width: 65px;
  height: 65px;
}

.showMore {
  margin: spacing(2) auto spacing(2) auto;

  @include devices(sm-up) {
    max-width: 50%;
  }
}
