@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.section {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
}

.sectionText {
  line-height: 1;

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.iconWrapper {
  margin-right: 5px;
}

.hintIcon.hintIcon {
  margin-left: 6px;
  color: var(--primary-main);

  &:hover {
    color: var(--primary-main);
  }
}
