@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

$VERTICAL_SPACING: 8;
$TOP_PANEL_INNER_HEIGHT: 8;
$TOP_PANEL_HEIGHT: $VERTICAL_SPACING * 2 + $TOP_PANEL_INNER_HEIGHT;

.topPanel {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include devices(md-up) {
    padding: spacing(0, 3);
  }
}

.button {
  background-color: transparent;
  transition: background-color 0.25s;
  z-index: 1;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.downloadLink {
  color: var(--common-white);
  z-index: 1;
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--common-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.closePreview {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: transparent;
  border: 0;
}

.image {
  max-width: 100%;
  object-fit: scale-down;
  max-height: calc(100% - #{$TOP_PANEL_HEIGHT * 3}px);
  z-index: 1;
}
