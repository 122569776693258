.tile {
  width: 34px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--contextual-colors-ui-secondary);
  border-radius: 6px;
}

.expertPhoto {
  border-radius: 4px;
  max-width: 34px;
}
