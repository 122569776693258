@use "@styles/partials/functions" as *;

.appBar {
  box-shadow: none;
  border-bottom: 1px solid var(--contextual-colors-neutral);
  position: relative;
  z-index: 100;
}

.tabs {
  min-height: 0;
}

.tab {
  padding: spacing(0.75, 1.5);

  &:hover {
    color: var(--primary-main);
    transition: color 0.3s ease;
  }

  &:disabled {
    color: var(--primary-main);
  }
}
