@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.miniAttachment {
  display: flex;
  min-width: 0;
}

.chip {
  border: 1px solid var(--contextual-colors-ui-secondary);
  border-radius: 9px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 18px;
  padding: spacing(0, 0.5);

  @include devices(sm-up) {
    border-radius: 10px;
    height: 20px;
  }
}

.chipTitle {
  font-size: rem(10);

  @include devices(sm-up) {
    font-size: rem(11);
  }
}

.firstAttachment {
  padding: spacing(0, 1);
  min-width: 0;
}

.attachmentIcon {
  margin-right: 3px;
  min-height: 14px;
  min-width: 14px;
  max-height: 14px;
  max-width: 14px;

  @include devices(sm-up) {
    margin-right: 5px;
  }
}

.attachmentTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.moreAttachments {
  margin-left: spacing(0.5);
}
