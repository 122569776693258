@use "@styles/partials/functions" as *;

.tableBody {
  & td {
    font-weight: var(--font-weight-medium);
    font-size: rem(14);
  }
}

.firstColumn {
  padding-left: spacing(7.5);
}

.firstColumnBox {
  display: flex;
  align-items: center;
}

.th {
  color: var(--text-secondary);
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
}

.tableRow {
  &:last-child td {
    border-bottom: none;
  }
}

.cellInner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bodyText {
  padding-left: spacing(7.5);
  border-bottom: none;
}

.priceIncoming {
  background-color: var(--contextual-colors-bg-primary-accent);
  color: var(--primary-dark);
  border-radius: 4px;
  padding: spacing(0.25);
}

.envelopeIcon {
  width: 18px;
  height: 18px;
}
