@use "@styles/partials/functions" as *;

.list {
  list-style-type: none;
  margin: spacing(-2, 0, 0);
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.listItem {
  margin-right: spacing(2);
  margin-top: spacing(2);
  display: inline-block;
}
