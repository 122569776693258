@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.progressPaper {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: spacing(3, 6);

  @include devices(md-up) {
    padding: spacing(4, 8);
  }
}

.activityIcon {
  width: 85px !important;
  height: 61px !important;
  margin-bottom: spacing(2) !important;

  @include devices(md-up) {
    width: 124px !important;
    height: 89px !important;
  }
}

.chatNotePaper {
  background-color: var(--contextual-colors-bg-secondary-accent);
}

.chatNotePaperInset {
  margin-top: spacing(2);
}

.chatNoteSubtitle {
  font-size: rem(14);
  line-height: 1.2;

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.chatLink {
  display: block;
  color: var(--primary-main);
  text-decoration: underline dashed;

  @include devices(md-up) {
    display: inline-flex;
    align-items: center;
  }
}

.chatIndicator {
  margin-right: spacing(1.5);

  @include devices(md-up) {
    margin-left: spacing(1.5);
  }
}
