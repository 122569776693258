@use "@styles/partials/functions" as *;

.attachmentWrapper {
  width: 120px;
  position: relative;
  padding-bottom: 40px;
  height: 105px;
  display: flex;
  background-color: var(--contextual-colors-negative);
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.attachmentWrapperPreviewable {
  padding-bottom: 20px;
}

.thumbnail {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  word-break: break-all;
}

.formatPreview {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
}

.formatIcon {
  width: 27px;
  height: 30px;
}

.panel {
  padding: 6px 36px 5px 8px; // Adjusted padding to pixel values
  background-color: var(--contextual-colors-ui-secondary);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.title {
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  line-height: 110%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  display: block;
}

.size {
  font-size: rem(10);
  text-transform: uppercase;
  white-space: nowrap;
  display: block;
}

.saveIconWrapper {
  color: inherit;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  height: 100%;
  margin: 0 10px;
  top: 0;
  cursor: pointer;
  transition: color 0.25s;

  &:hover {
    color: var(--primary-main);
  }
}
