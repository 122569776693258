@use "@styles/partials/functions" as *;

.simpleNavBar {
  gap: spacing(2);
}

.subtitle2.subtitle2 {
  margin-bottom: 0;
}

.editButtonBase {
  position: absolute;
  right: 0;
  padding: spacing(
    1.5,
    2
  ) !important; // inject order bug https://adviqo.atlassian.net/browse/MISSION-3281?focusedCommentId=201950
}

.editButtonEdit {
  bottom: 4px;
}

.editButtonClose {
  z-index: 1;
  top: 0;
  transform: translate3d(spacing(2), spacing(-1.5), 0);
  padding-right: 0;
}
